import { React, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import logo from '../assets/logo.png';

const Header = () => {
    const location = useLocation();
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    }

    return (
        <header className="text-blue-500 top-0 left-0 right-0 fixed z-50" style={{ backgroundColor: 'transparent' }}>
            <div className="container mx-auto px-4 py-4 flex justify-between items-center">
                <Link to="/" className="flex items-center">
                    <img src={logo} alt="Stud Tv Installs Logo" className="h-8 w-8 mr-2" />
                    <span className="text-2xl font-bold drop-shadow-md">Stud TV Installs</span>
                </Link>
                <nav className="hidden md:flex space-x-4">
                    <Link to="/" className={`hover:underline ${location.pathname === '/' && 'underline drop-shadow-md'}`} style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Home</Link>
                    <Link to="/services" className={`hover:underline ${location.pathname === '/services' && 'underline drop-shadow-md'}`} style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Services</Link>
                    <Link to="/gallery" className={`hover:underline ${location.pathname === '/gallery' && 'underline drop-shadow-md'}`} style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Previous Work</Link>
                    <Link to="/about" className={`hover:underline ${location.pathname === '/about' && 'underline drop-shadow-md'}`} style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>About</Link>
                    <Link to="/quote" className={`hover:underline ${location.pathname === '/quote' && 'underline drop-shadow-md'}`} style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Get a Free Quote</Link>
                </nav>
                <button className="md:hidden" onClick={toggleDrawer}>
                    <FaBars />
                </button>
            </div>
            {drawerOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-75 z-50 md:hidden">
                    <div className="absolute top-0 right-0 p-4">
                        <button onClick={toggleDrawer}>
                            <FaTimes className="text-white" />
                        </button>
                    </div>
                    <div className="flex flex-col items-center justify-center h-full space-y-4">
                        <Link to="/" onClick={toggleDrawer} className={`text-white text-xl ${location.pathname === '/' && 'underline'}`} style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Home</Link>
                        <Link to="/services" onClick={toggleDrawer} className={`text-white text-xl ${location.pathname === '/services' && 'underline'}`} style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Services</Link>
                        <Link to="/gallery" onClick={toggleDrawer} className={`text-white text-xl ${location.pathname === '/gallery' && 'underline'}`} style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Previous Work</Link>
                        <Link to="/about" onClick={toggleDrawer} className={`text-white text-xl ${location.pathname === '/about' && 'underline'}`} style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>About</Link>
                        <Link to="/quote" onClick={toggleDrawer} className={`text-white text-xl ${location.pathname === '/quote' && 'underline'}`} style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Get a Free Quote</Link>
                    </div>
                </div>
            )}
        </header>
    )
}

export default Header;
