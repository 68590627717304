import React from 'react';
import { FaPhoneAlt, FaMapMarkerAlt, FaEnvelope } from 'react-icons/fa';
import IframeResizer from '@iframe-resizer/react';
import { motion } from 'framer-motion';
import about from '../assets/about.jpg';
import location from '../assets/location.jpg';
import { Link } from 'react-router-dom';

const About = () => {
  const images = [about];
  const bgNum = Math.floor(Math.random() * 1);
  const bgImage = images[bgNum];

  return (
    <div className="text-center">
      <motion.div
        className="relative h-80 bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center flex-col">
          <h1 className="text-white text-4xl font-bold mb-4">About Us</h1>
          <motion.div
            whileHover={{ scale: 1.1, translateZ: 50, perspective: 1000, boxShadow: '0px 20px 30px rgba(0, 0, 0, 0.4)', transition: { duration: 0.2 } }}
            className="inline-block mb-4"
          >
            <Link to="/quote" className="bg-blue-500 text-white px-4 py-2 rounded font-bold">
              ↗️ Free Quote
            </Link>
          </motion.div>
        </div>
      </motion.div>
      <div className="container mx-auto px-4 py-8"></div>
        <p className="text-lg text-black px-4">
          We mount any size, brand, and model TV. Whether it is being mounted to drywall, brick, or
          tile we can complete the task successfully. Along with TV Hanging Services we offer all
          Home Theater and Low Voltage Services.  Some examples are Projector & Screen installs,
          AVR setups, Flush mount ceiling speaker installs, Cable concealment and much much more.
          Stud TV Installs has built a strong reputation in the Seattle and the neighboring areas.
          That is why many of our customers continue to use us and are repeat customers. <br /><br />

          Attempting to do the job yourself puts you and your expensive home theater equipment at risk.
          Most people don't have the experience to perform the installation correctly the first time.
          We have the experience of 10 years and have seen it all. <br /><br />

          Furthermore, we provide an accurate quote for the amount you ll owe before we show up.
          We don't charge hidden fees or add on extra costs at the end of our service.
          The professionalism and honesty of our techs allows you to relax, sit back and enjoy
          your new home theater installation. <br /><br />

          So go ahead and fill out the request form for a quote.
          Provide as much detail as you can and choose a time slot that fits your schedule.
          We will quickly send you an estimate for approval. Once approved we quickly work on
          finding a slot that fits your schedule. <br /><br />
        </p>
      <div className="container mx-auto px-4 py-8">
        <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
        <div className="flex flex-col items-center space-y-4 mb-8">
          <div className="flex items-center space-x-2">
            <FaPhoneAlt className="text-blue-500" style={{ verticalAlign: 'middle' }} />
            <p className="text-lg">Phone: (425) 495-9981</p>
          </div>
          <div className="flex items-center space-x-2">
            <FaMapMarkerAlt className="text-red-500" style={{ verticalAlign: 'middle' }} />
            <p className="text-lg">21250 40th Way S, SeaTac, WA 98198</p>
          </div>
          <motion.div
            className="flex items-center space-x-2"
            whileHover={{ scale: 1.1, transition: { duration: 0.3 } }}
          >
            <FaEnvelope className="text-blue-500" style={{ verticalAlign: 'middle' }} />
            <a
              href="mailto:studtvinstalls@gmail.com"
              className="bg-blue-500 text-white px-4 py-2 rounded font-bold flex items-center space-x-2"
              style={{ perspective: '500px' }}
            >
              Email us
            </a>
          </motion.div>
        </div>
        <div className="flex flex-col items-center space-y-4 mb-2">
          <h2 className="text-2xl font-bold ">Our Hours</h2>
          <p className="text-lg">Monday - Friday: 9:00 AM - 7:00 PM</p>
          <p className="text-lg">Saturday & Sunday: 10:00 AM - 5:00 PM</p>
        </div>
        <img src={location} alt="Location" className='mx-auto w-11/12 sm:w-3/5 pb-4' />
        <script src="https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.4.4/iframeResizer.min.js"></script>
        <IframeResizer
          license="GPLv3"
          src="https://177285f8c9774fc9848dee573e693a26.elf.site"
          style={{ width: '100%', border: 0 }}
          waitForLoad
        />
      </div>
    </div>
  );
};

export default About;
