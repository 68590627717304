import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import bg4 from '../assets/back4.png';
import av from '../assets/services/av.jpg';
import doorbell from '../assets/services/doorbell.jpg';
import mount from '../assets/services/mount.jpg';
import projector from '../assets/services/projector.jpg';
import screen from '../assets/services/screen.jpg';
import security from '../assets/services/camera.jpg';
import speaker from '../assets/services/speaker.jpg';
import surround from '../assets/gallery/gallery21.jpg';
import wire from '../assets/services/wired.jpg';
import theater from '../assets/services/theater.jpg';

const Services = () => {
  const images = [bg4];
  const bgNum = Math.floor(Math.random() * 1);
  const bgImage = images[bgNum];

  return (
    <div className="text-center">
      <motion.div
        className="relative h-96 bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center flex-col">
          <h1 className="text-white text-4xl font-bold mb-4">Our Services</h1>
          <motion.div
              whileHover={{ scale: 1.1, translateZ: 50, perspective: 1000, boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)", transition: { duration: 0.2 } }}
              className="inline-block mb-4"
            >
            <Link to="/quote" className="bg-blue-500 text-white px-4 py-2 rounded font-bold" >
              ↗️ Free Quote
            </Link>
          </motion.div>
        </div>
      </motion.div>
      <div className="max-w-4xl mx-auto text-white px-4 pt-4 pb-10">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 pt-4">
          <ServiceCard title="TV Mounting" backgroundImage={mount} />
          <ServiceCard title="In-wall Cable Concealment" backgroundImage={wire} />
          <ServiceCard title="Ceiling Speaker Installation" backgroundImage={speaker} />
          <ServiceCard title="Projector Installation" backgroundImage={projector} />
          <ServiceCard title="Projector Screen Installation" backgroundImage={screen} />
          <ServiceCard title="AVR & Home Theater Installation" backgroundImage={theater} />
          <ServiceCard title="Flush Mount/In-Wall Speaker Installs" backgroundImage={surround} />
          <ServiceCard title="Video Doorbell & Floodlight Installs" backgroundImage={doorbell} />
          <ServiceCard title="Security Camera Installs" backgroundImage={security} />
          <ServiceCard title="Troubleshooting & All Low Voltage Work" backgroundImage={av} />
        </div>
      </div>
    </div>
  );
};

const ServiceCard = React.forwardRef(({ title, backgroundImage }, ref) => {
  const { ref: inViewRef, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const cardStyle = {
    backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  return (
    <Link to="/quote" className="block" ref={inViewRef}>
      <motion.div
        className="bg-white bg-opacity-25 rounded-md p-4 flex flex-col items-center justify-center text-center hover:bg-opacity-50 transition duration-300 h-28 w-full"
        style={cardStyle}
        initial={{ opacity: 0, y: 50 }}
        animate={inView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.5 }}
        whileHover={{ scale: 1.1, translateZ: 50, perspective: 1000, boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)", transition: { duration: 0.2 } }}
      >
        <h2 className="text-xl font-semibold mb-2">{title}</h2>
      </motion.div>
    </Link>
  );
});

export default Services;

