import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import gallery2 from '../assets/gallery/gallery2.jpg';
import gallery3 from '../assets/gallery/gallery3.jpg';
import gallery4 from '../assets/gallery/gallery4.jpg';
import gallery5 from '../assets/gallery/gallery5.jpg';
import gallery6 from '../assets/gallery/gallery6.jpg';
import gallery7 from '../assets/gallery/gallery7.jpg';
import gallery8 from '../assets/gallery/gallery8.jpg';
import gallery9 from '../assets/gallery/gallery9.jpg';
import gallery10 from '../assets/gallery/gallery10.jpg';
import gallery11 from '../assets/gallery/gallery11.jpg';
import gallery12 from '../assets/gallery/gallery12.jpg';
import gallery13 from '../assets/gallery/gallery13.jpg';
import gallery14 from '../assets/gallery/gallery14.jpg';
import gallery15 from '../assets/gallery/gallery15.jpg';
import gallery16 from '../assets/gallery/gallery16.jpg';
import gallery17 from '../assets/gallery/gallery17.jpg';
import gallery18 from '../assets/gallery/gallery18.jpg';
import gallery19 from '../assets/gallery/gallery19.jpg';
import gallery20 from '../assets/gallery/gallery20.jpg';
import gallery22 from '../assets/gallery/gallery22.jpg';
import gallery24 from '../assets/gallery/gallery24.jpg';
import gallery25 from '../assets/gallery/gallery25.jpg';
import gallery26 from '../assets/gallery/gallery26.jpg';
import gallery27 from '../assets/gallery/gallery27.jpg';
import gallery28 from '../assets/gallery/gallery28.jpg';
import gallery29 from '../assets/gallery/gallery29.jpg';
import gallery30 from '../assets/gallery/gallery30.jpg';
import gallery31 from '../assets/gallery/gallery31.jpg';
import gallery32 from '../assets/gallery/gallery32.jpg';
import gallery33 from '../assets/gallery/gallery33.jpg';
import gallery34 from '../assets/gallery/gallery34.jpg';
import gallery35 from '../assets/gallery/gallery35.jpg';
import gallery36 from '../assets/gallery/gallery36.jpg';
import gallery37 from '../assets/gallery/gallery37.jpg';
import gallery38 from '../assets/gallery/gallery38.jpg';
import gallery39 from '../assets/gallery/gallery39.jpg';
import gallery40 from '../assets/gallery/gallery40.jpg';
import gallery41 from '../assets/gallery/gallery41.jpg';
import gallery42 from '../assets/gallery/gallery42.jpg';
import gallery43 from '../assets/gallery/gallery43.jpg';
import gallery44 from '../assets/gallery/gallery44.jpg';
import gallery45 from '../assets/gallery/gallery45.jpg';
import gallery46 from '../assets/gallery/gallery46.jpg';
import gallery48 from '../assets/gallery/gallery48.jpg';
import gallery49 from '../assets/gallery/gallery49.jpg';
import gallery51 from '../assets/gallery/gallery51.jpg';
import gallery52 from '../assets/gallery/gallery52.jpg';
import gallery53 from '../assets/gallery/gallery53.jpg';
import gallery54 from '../assets/gallery/gallery54.jpg';
import gallery55 from '../assets/gallery/gallery55.jpg';
import gallery56 from '../assets/gallery/gallery56.jpg';
import gallery57 from '../assets/gallery/gallery57.jpg';
import gallery58 from '../assets/gallery/gallery58.jpg';
import gallery59 from '../assets/gallery/gallery59.jpg';
import gallery60 from '../assets/gallery/gallery60.jpg';
import gallery61 from '../assets/gallery/gallery61.jpg';
import gallery62 from '../assets/gallery/gallery62.jpg';
import gallery63 from '../assets/gallery/gallery63.jpg';


import bg1 from '../assets/bg1.jpg';
import bg2 from '../assets/bg2.jpg';
import bg3 from '../assets/bg3.jpg';
import bg4 from '../assets/bg4.jpg';

const images = [
    { src: gallery2 },
    { src: gallery3 },
    { src: gallery4 },
    { src: gallery5 },
    { src: gallery6 },
    { src: gallery7 },
    { src: gallery8 },
    { src: gallery9 },
    { src: gallery10 },
    { src: gallery11 },
    { src: gallery12 },
    { src: gallery13 },
    { src: gallery14 },
    { src: gallery15 },
    { src: gallery16 },
    { src: gallery17 },
    { src: gallery18 },
    { src: gallery19 },
    { src: gallery20 },
    { src: gallery22 },
    { src: gallery24 },
    { src: gallery25 },
    { src: gallery26 },
    { src: gallery27 },
    { src: gallery28 },
    { src: gallery29 },
    { src: gallery30 },
    { src: gallery31 },
    { src: gallery32 },
    { src: gallery33 },
    { src: gallery34 },
    { src: gallery35 },
    { src: gallery36 },
    { src: gallery37 },
    { src: gallery38 },
    { src: gallery39 },
    { src: gallery40 },
    { src: gallery41 },
    { src: gallery42 },
    { src: gallery43 },
    { src: gallery44 },
    { src: gallery45 },
    { src: gallery46 },
    { src: gallery48 },
    { src: gallery49 },
    { src: gallery51 },
    { src: gallery52 },
    { src: gallery53 },
    { src: gallery54 },
    { src: gallery55 },
    { src: gallery56 },
    { src: gallery57 },
    { src: gallery58 },
    { src: gallery59 },
    { src: gallery60 },
    { src: gallery61 },
    { src: gallery62 },
    { src: gallery63 },
];

const Gallery = () => {
    const bgimages = [bg1, bg2, bg3, bg4];
    const bgNum = Math.floor(Math.random() * 4);
    const bgImage = bgimages[bgNum];

    const [selectedImage, setSelectedImage] = useState(null);

    const openImage = (index) => {
        setSelectedImage(index);
    };

    const closeImage = () => {
        setSelectedImage(null);
    };

    return (
        <div className="flex flex-col min-h-screen">
            <motion.div
                className="relative h-96 bg-cover bg-center"
                style={{ backgroundImage: `url(${bgImage})` }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
            >
                <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center flex-col">
                    <h1 className="text-white text-4xl font-bold mb-4 px-6">Past Work</h1>
                    <motion.div
                        whileHover={{ scale: 1.1, translateZ: 50, perspective: 1000, boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)", transition: { duration: 0.2 } }}
                        className="inline-block mb-4"
                    >
                        <Link to="/quote" className="bg-blue-500 text-white px-4 py-2 rounded font-bold">
                            ↗️ Free Quote
                        </Link>
                    </motion.div>
                </div>
            </motion.div>
            <div className="container mx-auto px-4 py-8 flex-1">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {images.map((image, index) => (
                        <motion.div
                            key={index}
                            className="border"
                            whileHover={{ scale: 1.1, translateZ: 50, perspective: 1000, boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.4)", transition: { duration: 0.2 } }}
                            whileTap={{ scale: 0.95 }}
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            transition={{ duration: 0.5 }}
                            onClick={() => openImage(index)}
                        >
                            <img src={image.src} alt={`Gallery ${index}`} className="w-full h-96 object-cover" />
                        </motion.div>
                    ))}
                </div>
            </div>

            {selectedImage !== null && (
                <motion.div
                    className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                    onClick={closeImage}
                >
                    <motion.img
                        src={images[selectedImage].src}
                        alt={`Gallery ${selectedImage}`}
                        className="max-w-full max-h-full px-4 sm: py-3 px-0"
                        initial={{ y: "-100vh" }}
                        animate={{ y: 0 }}
                        transition={{ type: "spring", stiffness: 100, damping: 20 }}
                    />
                </motion.div>
            )}
        </div>
    );
};

export default Gallery;
