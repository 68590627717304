import React from 'react';
import { motion } from 'framer-motion';
import b4 from '../assets/back4.png';
import QuoteComponent from '../components/Quote'; 

const Quote = () => {
  const images = [b4];
  const bgNum = Math.floor(Math.random() * images.length); 
  const bgImage = images[bgNum];

  return (
    <div className=''>
      <motion.div
        className="relative h-32 bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
      </motion.div>
      
      <QuoteComponent />
    </div>
  );
};

export default Quote;
