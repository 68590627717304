import React, { useEffect } from 'react';

import bg1 from '../assets/bg1.jpg';
import bg2 from '../assets/bg2.jpg';
import bg3 from '../assets/bg3.jpg';
import bg4 from '../assets/bg4.jpg';

const QuoteComponent = () => {
  const images = [bg1, bg2, bg3, bg4];
  const bgNum = Math.floor(Math.random() * images.length); // Use images.length to get a random index
  const bgImage = images[bgNum];

  useEffect(() => {
    const existingForm = document.querySelector('#c6fde928-8345-40b7-b68f-1b3df18d3255');

    if (!existingForm) {
      const form = document.createElement('div');
      form.id = 'c6fde928-8345-40b7-b68f-1b3df18d3255';
      document.body.appendChild(form);
    }

    const existingLink = document.querySelector('link[href="https://d3ey4dbjkt2f6s.cloudfront.net/assets/external/work_request_embed.css"]');
    const existingScript = document.querySelector('script[src="https://d3ey4dbjkt2f6s.cloudfront.net/assets/static_link/work_request_embed_snippet.js"]');

    if (!existingLink) {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = 'https://d3ey4dbjkt2f6s.cloudfront.net/assets/external/work_request_embed.css';
      link.media = 'screen';
      document.head.appendChild(link);
    }

    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://d3ey4dbjkt2f6s.cloudfront.net/assets/static_link/work_request_embed_snippet.js';
      script.setAttribute('clienthub_id', 'c6fde928-8345-40b7-b68f-1b3df18d3255');
      script.setAttribute('form_url', 'https://clienthub.getjobber.com/client_hubs/c6fde928-8345-40b7-b68f-1b3df18d3255/public/work_request/embedded_work_request_form');
      document.body.appendChild(script);
    }

    return () => {
      if (!existingForm) {
        document.body.querySelector('#c6fde928-8345-40b7-b68f-1b3df18d3255').remove();
      }
      if (!existingLink) {
        document.head.querySelector('link[href="https://d3ey4dbjkt2f6s.cloudfront.net/assets/external/work_request_embed.css"]').remove();
      }
      if (!existingScript) {
        document.body.querySelector('script[src="https://d3ey4dbjkt2f6s.cloudfront.net/assets/static_link/work_request_embed_snippet.js"]').remove();
      }
    };
  }, []); // Empty dependency array to run useEffect only once

  return (
    <div id="c6fde928-8345-40b7-b68f-1b3df18d3255" style={{ overflow: "hidden", background: 'transparent' }}></div>
  );
};

export default QuoteComponent;
